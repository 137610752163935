import produce from 'immer';
import { get as _get } from 'lodash'
import * as actionTypes from 'store/actionTypes';


export function getInitialState() {
  return {
    token: null,
    user: null
  };
}

const SessionReducer = (state: any = getInitialState(), action: any) =>
  produce(state, (draft: any) => {
    const { payload } = action;
    switch (action.type) {
      case actionTypes.SET_TOKEN_ACTION: {
        draft.token = payload;
        break;
      }
      case actionTypes.SET_USER_ACTION: {
        draft.user = payload;
        break;
      }
      case actionTypes.LOGOUT_ACTION: {
        draft.user = null
        draft.token = null
        break;
      }
    }
  });

export default SessionReducer;
