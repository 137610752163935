export const SET_TOKEN_ACTION = 'SET_TOKEN_ACTION'
export const CREATE_ACCOUNT_ACTION = 'CREATE_ACCOUNT_ACTION'
export const CREATE_ACCOUNT_LOADING = 'CREATE_ACCOUNT_LOADING'
export const SET_USER_ACTION = 'SET_USER_ACTION'
export const LOAD_DAO_ACTION = 'LOAD_DAO_ACTION'
export const LOAD_DAO_LOADING = 'LOAD_DAO_LOADING'
export const LOAD_DAO_SUCCESS = 'LOAD_DAO_SUCCESS'
export const LOGOUT_ACTION = 'LOGOUT_ACTION'
export const DELETE_DAO_ACTION = 'DELETE_DAO_ACTION'
export const DELETE_DAO_LOADING = 'DELETE_DAO_LOADING'
export const DELETE_DAO_SUCCESS = 'DELETE_DAO_SUCCESS'