// eslint-disable-next-line no-unused-vars
import { LandingLayout, RootLayout, PrimaryLayout, DefaultLayout } from 'layouts';
import LoginPage from 'views/Login';
import DashboardPage from 'views/Dashboard';
import ElementsPage from 'views/Elements';

export default [
	{
		path: '/login',
		exact: true,
		layout: LandingLayout,
		private: false,
		component: LoginPage
	},
	{
		path: '/',
		exact: true,
		layout: DefaultLayout,
		private: true,
		component: DashboardPage
	},
	{
		path: '/elements',
		exact: true,
		layout: RootLayout,
		private: true,
		component: ElementsPage
	},
];
