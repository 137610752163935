import { Typography, Box, Chip } from '@mui/material';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Switch from 'components/Switch';
import Checkbox from 'components/Checkbox';
import LOCK_ICON from 'assets/svg/lock.svg'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
    chip: {
        backgroundColor: 'rgba(201,75,50, 0.1) !important',
        width: 110,
        height: 25,
        padding: "4px 20px",
        '& .MuiChip-label': {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '14px',
            color: '#B12F15'
        }
    },
  }));

export default () => {

    const classes = useStyles()
    
    return (
        <div>
            <Box mt={4}>
                <Typography variant='h4'>Buttons</Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Button variant='contained'>SEND</Button>
                    <Button disabled variant='contained'>SEND</Button> 
                    <Button size='small' variant='contained'>SEND</Button>
                    <Button variant='outlined'>SEND</Button>
                    <Button disabled variant='outlined'>SEND</Button> 
                    <Button size='small' variant='outlined'>SEND</Button>
                    <Button variant='contained' color='secondary'>SEND</Button>
                </Box>
                <Box display="flex" alignItems="center" mt={4} justifyContent="space-between">
                    <Button loading variant='contained'>SEND</Button>
                    <Button loading disabled variant='contained'>SEND</Button> 
                    <Button loading size='small' variant='contained'>SEND</Button>
                    <Button loading variant='outlined'>SEND</Button>
                    <Button loading disabled variant='outlined'>SEND</Button> 
                    <Button loading size='small' variant='outlined'>SEND</Button>
                    <Button loading variant='contained' color='secondary'>SEND</Button>
                </Box>
            </Box>
            <Box mt={4}>
                <Typography variant='h4'>Inputs</Typography>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <TextInput label="Enter your number" />
                    <TextInput label="Enter your wallet address" labelChip={<Chip sx={{ m:1 }} className={classes.chip} label="Required" size="small" />} />
                    <TextInput fullWidth error helperText="Address already exists" label="Enter your number" />
                    <TextInput fullWidth label="Enter your number" />
                </Box>
            </Box>
            <Box mt={4}>
                <Typography variant='h4'>Switch</Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Switch checkedIcon={LOCK_ICON} label="Whitelisted" />
                </Box>
            </Box>
            <Box mt={4}>
                <Typography variant='h4'>Checkbox</Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Checkbox />
                </Box>
            </Box>
        </div>
    )
}