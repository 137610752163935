import {
	call,
	put,
	takeLatest
} from 'redux-saga/effects';
import * as actionTypes from 'store/actionTypes';
import { get as _get } from 'lodash';
import { loadDAOService, deleteDAOService } from 'store/services/dao'

function* loadDAOSaga(action:any) {
	try {
	  yield put({ type: actionTypes.LOAD_DAO_LOADING, payload: true })
	  const { data } = yield call(loadDAOService, action.payload)
	  console.log('json, response', data)
		yield put({ type: actionTypes.LOAD_DAO_LOADING, payload: false })
		yield put({ type: actionTypes.LOAD_DAO_SUCCESS, payload: data })
		yield put({ type: actionTypes.LOAD_DAO_LOADING, payload: null })
	} catch (e) {

	}
  }

  function* deleteDAOSaga(action:any) {
	try {
	  yield put({ type: actionTypes.DELETE_DAO_LOADING, payload: action.payload })
	  const { data } = yield call(deleteDAOService, action.payload)
	  console.log('json, response', data)
		yield put({ type: actionTypes.DELETE_DAO_LOADING, payload: false })
		yield call(() => new Promise(resolve => setTimeout(resolve, 200)))
		yield put({ type: actionTypes.DELETE_DAO_SUCCESS, payload: action.payload })
		yield put({ type: actionTypes.DELETE_DAO_LOADING, payload: null })
	} catch (e) {

	}
  }

export default function* DAOSaga() {
	yield takeLatest(actionTypes.LOAD_DAO_ACTION, loadDAOSaga)
	yield takeLatest(actionTypes.DELETE_DAO_ACTION, deleteDAOSaga)
}