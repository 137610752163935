import { get as _get, find as _find, filter as _filter, uniq as _uniq } from 'lodash'
import { Grid, Box, Toolbar, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, MenuItem, FormGroup, FormControl, InputLabel, FormLabel, FormControlLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { LeapFrog } from "@uiball/loaders";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch } from 'hooks/useAppDispatch';
import palette from 'theme/palette';
import { useAppSelector } from 'hooks/useAppSelector';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { deleteDAOAction, loadDaoAction } from 'store/actions/dao';
import Table from 'components/Table'
import moment from 'moment'
import Button from 'components/Button';
import { CHAIN_INFO } from "constants/chainInfo";
import trimAddress from 'utils/sliceAddr';
import { filter } from '@chakra-ui/react';

type pageType = { limit: number, skip: number, sortField: string, sortDirection: string, filterField: any }

const useStyles = makeStyles((theme: any) => ({
    root: {
        paddingBottom: 60
    },
    logoContainer: {
        width: '30px !important',
        height: '30px !important',
        minWidth: '30px !important',
        minHeight: '30px !important',
        background: `linear-gradient(180deg, #FBF4F2 0%, #EEF1F5 100%)`,
        boxShadow: `inset 1px 0px 4px rgba(27, 43, 65, 0.1)`,
        borderRadius: 10,
        transform: `rotate(45deg)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    image: {
        transform: 'rotate(-45deg)',
        width: '141%',
        maxWidth: '141% !important',
        height: '141%;',
        flexShrink: 0
    },
    title: {
        fontWeight: '600 !important',
        fontSize: '10px !important',
        lineHeight: 25,
        textAlign: 'center',
        letterSpacing: '-0.011em !important',
        color: `${palette.primary.main} !important`,
        transform: `rotate(-45deg)`,
    },
    ChainLogo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#EEE',
        borderRadius: '50%',
        width: 20,
        height: 20,
        fontSize: '20px'
    },
}));


export default () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { account, provider, chainId } = useWeb3React();
    const [showDeleteAlert, setShowDeleteAlert] = useState<any>(null)
    const dispatch = useAppDispatch()
    const { dao, daoLoading, deleteDaoloading } = useAppSelector((store: any) => store?.dao)
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('createdAt');
    const [sortDirection, setSortDirection] = useState<string>('-1');
    const [filterField, setFilterField] = useState({
        totalBalance: false,
        transactionVolume: false
    });

    const renderImage = (DAO: any) => {
        if (!DAO) return ""
        return (
            <Box className={classes.logoContainer}>
                {DAO?.image ? <img className={classes.image} src={_get(DAO, 'image')} /> :
                    DAO && <Typography variant='h6' className={classes.title}>{

                        _get(DAO, 'name', '').length === 1
                            ?
                            _get(DAO, 'name', '')[0]?.charAt(0)?.toUpperCase()
                            :
                            _get(DAO, 'name', '')[0]?.charAt(0)?.toUpperCase() + _get(DAO, 'name', '')[_get(DAO, 'name', '')?.length - 1]?.charAt(0)?.toUpperCase()

                    }</Typography>
                }
            </Box>
        )
    }

    const COLUMNS = [
        {
            accessorKey: 'name',
            header: () => <span>Name</span>,
            cell: ({ row }: any) =>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {
                        renderImage(row?.original)
                    }
                    <div style={{ marginLeft: 12, fontWeight: 600 }}>{_get(row, 'original.name', null)}</div>
                </div>
        },
        {
            header: 'Created By',
            cell: ({ row }: any) =>
                <div style={{ minWidth: 80 }}>
                    {
                        _get(row, 'original.members', []).map((member: any) => {
                            if (member.creator == true) {
                                return (
                                    <div style={{ textAlign: 'center' }}>{_get(member, 'member.name', 'no name')}</div>

                                )
                            }
                            return null
                        })
                    }
                </div>,
        },
        {
            header: 'Member Count',
            cell: ({ row }: any) =>
                <div style={{ textAlign: 'center' }}>{_get(row, 'original.memberCount', null)}</div >,
        },
        {
            header: 'No. of Treasuries',
            cell: ({ row }: any) =>
                <div style={{ textAlign: 'center' }}>{_get(row, 'original.safeCount', null)}</div>,
        },
        {
            header: 'Treasury Balance',
            cell: ({ row }: any) =>
                <div>
                    {
                        _get(row, 'original.safes', []).map((safe: any) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '110px' }}>
                                        <img style={{ marginRight: '10px' }} src={CHAIN_INFO[safe.chainId]?.logoUrl} className={classes.ChainLogo} alt="seek-logo" />
                                        <div>{trimAddress(safe.address)}</div>
                                    </div>
                                    <div style={{ marginLeft: '10px' }}>{safe.balance === null ? '-' : `$${parseFloat(safe.balance).toFixed(2)}`}</div>
                                </div>
                            )
                        })
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <span style={{ width: '110px', marginLeft: '10px' }}>Total Balance </span>
                        <span style={{ fontWeight: 'bold' }}>${parseFloat(_get(row, 'original.totalBalance', null)).toFixed(2)}</span>
                    </div>
                </div >,
        },
        {
            header: 'Last Activity',
            cell: ({ row }: any) =>
                <div>{moment(_get(row, 'original.updatedAt', null)).format('MMM DD YYYY hh:mm A')}</div>,
        },
        {
            header: 'Created On',
            cell: ({ row }: any) =>
                <div>{moment(_get(row, 'original.createdAt', null)).format('MMM DD YYYY hh:mm A')}</div>,
        },
        {
            header: 'Transaction Volume via Lomads',
            columns: [
                {
                    header: 'Direct Payment',
                    cell: ({ row }: any) =>
                        <div style={{ textAlign: 'center' }}>{_get(row, 'original.directPaymentCount', null)}</div>,
                },
                {
                    header: 'Recurring Payments Count',
                    cell: ({ row }: any) =>
                        <div style={{ textAlign: 'center' }}>{_get(row, 'original.recurringPaymentCount', null)}</div>,
                },
            ],
            cell: ({ row }: any) =>
                <div style={{ minWidth: 150 }}>{moment(_get(row, 'original.createdAt', null)).format('MMM DD YYYY hh:mm A')}</div>,
        },

        // {
        //     header: 'No. of token-gated permissions',
        //     cell: ({ row }: any) => {
        //         const projects = _get(row, 'original.projects', [])
        //         const linkCount = projects.reduce((a: any, b: any) => {
        //             return a + _filter(_get(b, 'links', []), l => l?.accessControl).length
        //         }, 0)
        //         const unlockedCount = projects.reduce((a: any, b: any) => {
        //             return a + _get(b, 'links', []).reduce((s: any, x: any) => s + (_uniq(x?.unlocked)?.length) || 0, 0)
        //         }, 0)
        //         return (
        //             <div style={{ minWidth: 150, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        //                 <div>links: {linkCount}</div>
        //                 <div style={{ marginLeft: 16 }}>unlocked: {unlockedCount}</div>
        //             </div>
        //         )
        //     }
        // },
        {
            header: 'Actions',
            cell: ({ row }: any) => {
                // const creator = _find(row?.original?.members, (m: any) => m.creator)
                // console.log("creator", creator)
                // if (creator && creator?.member?.wallet?.toLowerCase() === account?.toLowerCase()) {
                return (
                    <IconButton disabled={deleteDaoloading} onClick={() => setShowDeleteAlert(row?.original?.url)}>
                        {deleteDaoloading === row?.original?._id ? <LeapFrog size={50} color="#C94B32" /> : <DeleteIcon />}
                    </IconButton>
                )
                // }
                // return null
            },
        },
    ]

    const loadData = ({ limit = 10, skip = 0, sortField = "createdAt", sortDirection = '-1', filterField = {
        totalBalance: false,
        transactionVolume: false
    } }: pageType) => {
        dispatch(loadDaoAction({ limit, skip, sortField, sortDirection, filterField: JSON.stringify(filterField) }))
    }

    const handleDelete = (url: any) => {
        setShowDeleteAlert(null)
        dispatch(deleteDAOAction(url))
    }

    const handleSortFieldChange = (event: SelectChangeEvent) => {
        setSortField(event.target.value as string);
        loadData({ limit: pageSize, skip: 0, sortField: event.target.value, sortDirection: sortDirection, filterField: filterField })
    };

    const handleSortDirectionChange = (event: SelectChangeEvent) => {
        setSortDirection(event.target.value as string);
        loadData({ limit: pageSize, skip: 0, sortField: sortField, sortDirection: event.target.value, filterField: filterField })
    };

    const handleFilterFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterField({
            ...filterField,
            [event.target.name]: event.target.checked
        });

        loadData({
            limit: pageSize, skip: pageIndex * pageSize, sortField: sortField, sortDirection: event.target.value, filterField: {
                ...filterField,
                [event.target.name]: event.target.checked
            }
        })
    };

    const { totalBalance, transactionVolume } = filterField;

    useEffect(() => loadData({
        limit: pageSize,
        skip: pageIndex * pageSize,
        sortField: "createdAt",
        sortDirection: '-1',
        filterField: {
            totalBalance: false,
            transactionVolume: false
        }
    }), []);

    console.log(dao?.data)

    return (
        <Grid sx={{ pt: 12 }} item mt={3} pb={6} sm={12}>
            <Box sx={{ flexGrow: 1 }}>
                <Toolbar
                    style={{
                        borderRadius: 5,
                        padding: '20px 22px 15px',
                        background: '#FFF'
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            color: '#76808D',
                            fontSize: 22,
                            fontWeight: 400,
                            marginRight: 50
                        }}
                        component="div"
                    >
                        Organizations ({dao?.itemCount})
                    </Typography>
                    {/* Select for Sort Field */}
                    <Box sx={{ minWidth: 150, marginRight: '30px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="sort-by-select-label">Sort by</InputLabel>
                            <Select
                                labelId="sort-by-select-label"
                                id="sort-by-select"
                                value={sortField}
                                label="Sort by"
                                onChange={handleSortFieldChange}
                            >
                                <MenuItem value="memberCount">Member Count</MenuItem>
                                <MenuItem value="totalBalance">Treasury Balance</MenuItem>
                                <MenuItem value="createdAt">Created Date</MenuItem>
                                <MenuItem value="updatedAt">Last Activity Date</MenuItem>
                                <MenuItem value="transactionVolume">Transaction Volume</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {/* Select for Sort Direction */}
                    <Box sx={{ minWidth: 150 }}>
                        <FormControl fullWidth>
                            <InputLabel id="sort-direction-select-label">Sort direction</InputLabel>
                            <Select
                                labelId="sort-direction-select-label"
                                id="sort-direction-select"
                                value={sortDirection}
                                label="Sort by"
                                onChange={handleSortDirectionChange}
                            >
                                <MenuItem value="-1">Descending</MenuItem>
                                <MenuItem value="1">Aescending</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ ml: 8 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Filter by</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={totalBalance} onChange={handleFilterFieldChange} name="totalBalance" />
                                    }
                                    label="Treasury Balance"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={transactionVolume} onChange={handleFilterFieldChange} name="transactionVolume" />
                                    }
                                    label="Transaction Volume"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </Toolbar>
            </Box >
            <Box mt={0.5}>
                {!dao && daoLoading ?
                    <Box>
                        <Typography>Loading..</Typography>
                    </Box> :
                    <Table
                        data={dao?.data}
                        size="medium"
                        columns={COLUMNS}
                        pageCount={dao?.totalPages}
                        itemCount={dao?.itemCount}
                        onFetch={
                            ({ pageIndex, pageSize }: any) => {
                                setPageSize(pageSize)
                                setPageIndex(pageIndex)
                                loadData({ limit: pageSize, skip: pageIndex * pageSize, sortField: sortField, sortDirection: sortDirection, filterField: filterField })
                            }
                        }
                    />
                }
            </Box>
            <Dialog
                open={showDeleteAlert}
                onClose={() => setShowDeleteAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" onClick={() => setShowDeleteAlert(false)}>Cancel</Button>
                    <Button size="small" variant="contained" onClick={() => handleDelete(showDeleteAlert)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid >
    )
}