import produce from 'immer';
import { get as _get } from 'lodash'
import * as actionTypes from 'store/actionTypes';


export function getInitialState() {
  return {
    dao: null,
    daoLoading: null,
    deleteDaoloading: null
  };
}

const DaoReducer = (state: any = getInitialState(), action: any) =>
  produce(state, (draft: any) => {
    const { payload } = action;
    switch (action.type) {
      case actionTypes.LOAD_DAO_SUCCESS: {
        draft.dao = payload;
        break;
      }
      case actionTypes.LOAD_DAO_LOADING: {
        draft.daoLoading = payload;
        break;
      }
      case actionTypes.LOGOUT_ACTION: {
        draft.dao = null
        draft.daoLoading = null
        break;
      }
      case actionTypes.DELETE_DAO_LOADING: {
        draft.deleteDaoloading = payload
        break;
      }
      case actionTypes.DELETE_DAO_SUCCESS: {
        draft.dao = {
          ...state?.dao,
          data: state?.dao?.data.filter((dao: any) => dao?.url !== payload)
        }
      }
    }
  });

export default DaoReducer;
