import { useCallback, useEffect, useMemo, useState } from 'react'
import { throttle as _throttle, debounce as _debounce, get as _get } from 'lodash'
import { Grid, Typography, Box} from "@mui/material"
import { makeStyles } from '@mui/styles';
import Button from "components/Button";
import { getSigner } from 'utils'
import CHEERS from 'assets/svg/cheers.svg'
import { Connector } from "@web3-react/types";
import { injectedConnection } from "connection";
import LOMADS_LOGO from 'assets/svg/lomadsfulllogo.svg'
import METAMASK from 'assets/svg/metamask.svg'
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from 'constants/chain';
import Web3Token from 'web3-token';
import { isChainAllowed} from "utils/switchChain";
import { createAccountAction, setTokenAction } from 'store/actions/session';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
    root: {
         height: "100vh",
         maxHeight: 'fit-content',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center',
         overflow: 'hidden !important'
    },
    logo: {
        width: 138,
        height: 81
    },
    cheers: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center'
    },
    metamaskButton: {
        height: '111px !important',
        cursor: 'pointer',
        alignContent: "inherit",
        background: "#fff",
        borderColor: "#c94b32",
        borderRadius: '10px !important',
        borderWidth: 0,
        filter: "drop-shadow(3px 5px 4px rgba(27,43,65,.05)) drop-shadow(-3px -3px 8px rgba(201,75,50,.1)) !important",
        margin: "10px",
        padding: 40
    },
    select: {
        background: '#FFF',
        borderRadius: '10px !important',
        boxShadow: 'none !important',
        fontSize: '16px !important',
        minWidth: 'inherit !importnt',
        padding: '0px !important'
    }
  }));

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get("from")
    const { token, user } = useSelector((store: any) => store.session);
    const { chainId, connector, account, provider } = useWeb3React();
    console.log("account", account)
    const [currentChain, setCurrentChain] = useState(SupportedChainId.POLYGON)


    const chainAllowed = useMemo(() => {
        if(!chainId) return false;
        return isChainAllowed(connector, chainId)
    }, [connector, chainId])

    useEffect(() => {
        if(token && user && account) {
            if(from)
                navigate(from)
            else
                navigate('/') 
        }
    }, [token, account, user])

    const generateToken = async () => {
        if (!token) {
          if(provider && account){
            const signer = getSigner(provider, account)
            const token = await Web3Token.sign(async (msg: string) => await signer.signMessage(msg), '365d');
            dispatch(createAccountAction(token))
          }
        }
      }

    const handleLogin = useCallback(_debounce(async (connector: Connector) => {
        try {
            dispatch(setTokenAction(null))
            if (!account)
                await connector.activate()
            else {
                console.log("active accout", account)
                await generateToken()
            }

        } catch (e) {
            console.log(e)
        }
    }, 1000), [chainAllowed, account, chainId, currentChain])

    return (
        <>
            <Grid container className={classes.root}>
                <Grid xs={12} item display="flex" flexDirection="column" alignItems="center">
                    <Box zIndex={0} position="absolute" bottom={0}>
                        <img src={CHEERS} style={{ marginBottom: '-5px' }} />
                    </Box>
                    <Box mb={12} mt={3}>
                        <img src={LOMADS_LOGO} />
                    </Box>
                    <Typography my={1} variant="subtitle1">Hello there !</Typography>
                    <Typography mt={2} mb={4} color="primary" variant="h2">Connect Your Wallet</Typography>
                    <Button onClick={() => handleLogin(injectedConnection.connector)} className={classes.metamaskButton} variant='contained' color='secondary'>
                        <img src={METAMASK} />
                    </Button>
                    <Box height={200}></Box>
                </Grid>
            </Grid>
        </>
    )
}