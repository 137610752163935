import React, { useEffect, useState } from 'react';
import { pick as _pick, get as _get } from 'lodash';
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import FullScreenLoader from 'components/FullScreenLoader';

const PrivateRoute = (props: any) => {
	const web3React = useWeb3React()
	const { token, user } = useSelector((store:any) => store.session);
	// if(!web3React.account)
	// 	return <FullScreenLoader/>
	if ((!web3React.account || !token || !user) && props.private) {
		if(window.location.pathname !== '/')
			window.location.href = `/login?from=${window.location.pathname}`
		else
			window.location.href = `/login`
	} else {
		return props.orRender;
	}
};
export default PrivateRoute;
