import * as actionTypes from '../actionTypes'

export const loadDaoAction = (payload: any) => {
    return {
        type: actionTypes.LOAD_DAO_ACTION,
        payload
    }
}

export const deleteDAOAction = (payload: string) => {
    return {
        type: actionTypes.DELETE_DAO_ACTION,
        payload
    }
}